import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core";
import PropTypes from 'prop-types';



class Telefonia extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosKba({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(Telefonia, 'VDPNTelefonia')
Telefonia.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
Telefonia.defaultProps = {
    companyName: "AFI",
    dataInfo: {
        "textHeader": "AVISO DE TELEFONÍA MÓVIL",
        "textBox": <strong>Términos y Condiciones</strong>,
        "textBoxTwo": "Autorizas a tu proveedor de servicio de telefonía móvil a usar o divulgar información sobre tu cuenta y tu dispositivo inalámbrico, si está disponible,  y a Apoyo Financiero, Inc. o a su proveedor de servicios durante la relación comercial, únicamente para ayudar a identificar tu dispositivo inalámbrico y para prevención de fraude. Consulta nuestra Política de privacidad para saber cómo tratamos tus datos.",
        "textBoxThree": "",
    }
}


